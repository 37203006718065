import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Bayenthal: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Bayenthal,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Bayenthal"
          d="M589.5,749.3l8.7,18.6c-0.1,0-40,24-40.2,24.1l-16-2l-6-50l22,4l18-6l5.2-4.4l5.1,9.2 C587.4,744.9,588.5,747.1,589.5,749.3z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 547.067 764.5834)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0" className="st6 st3 st4">
            Bayen-
          </tspan>
          <tspan x="0" y="14.4" className="st6 st3 st4">
            thal
          </tspan>
        </text>
      </g>
    </>
  );
});
